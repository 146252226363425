import NewsletterForm from "../modules/Forms/NewsletterForm";

NewsletterForm();


const progressLine = document.querySelector('.inside-bar1');
var swiper = new Swiper(".mySwiper", {
    effect: "fade",
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      on: {
        autoplayTimeLeft(s, time, progress) {
          progressLine.style.setProperty("--progress", progress);
        }
      }

});
const progressLine2 = document.querySelector('.inside-bar2');
var swiper2 = new Swiper(".mySwiper2", {
    effect: "fade",
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      on: {
        autoplayTimeLeft(s, time, progress) {
          progressLine2.style.setProperty("--progress", progress);
        }
      }
});

var swiper3 = new Swiper(".mySwiper3", {
    effect: "fade",
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    on: {
        slideChange: function () {
          const activeSlide = this.slides[this.activeIndex];
          const bgColor = activeSlide.getAttribute("data-bg-color");
          const bgImage = activeSlide.getAttribute("data-bg-image");
          changeBackground(bgColor, bgImage);
          console.log(this.activeIndex);
          document.querySelectorAll('.nav-bullet').forEach(item => item.classList.remove('active'));
          document.querySelector(`.bullet-${this.activeIndex}`).classList.add('active');
          if(this.activeIndex == 0) {
            gsap.to('.ball-vinhos', {
                left: '0%',
            })
          }
          if (this.activeIndex == 1) {
            gsap.to('.ball-vinhos', {
                left: '33%',
            })
          }
          if (this.activeIndex == 2) {
            gsap.to('.ball-vinhos', {
                left: '65%',
            })
          }
          if (this.activeIndex == 3) {
            gsap.to('.ball-vinhos', {
                left: '97%',
            })
          }
        },
      },
});
function changeBackground(color, image) {
    const backgroundContainer = document.querySelector(".slider-vinhos");
    gsap.to(backgroundContainer, {
        duration: 0.2,
        backgroundColor: color,
    })
    gsap.set(backgroundContainer, {'css':{'backgroundImage':'url(' + image + ')'}})
  };


const btn1 = document.querySelector('.bottle-1');
btn1.addEventListener('click', () => {
    swiper3.slideTo(0);
});

const btn2 = document.querySelector('.bottle-2');
btn2.addEventListener('click', () => {
    swiper3.slideTo(1);
});

const btn3 = document.querySelector('.bottle-3');
btn3.addEventListener('click', () => {
    swiper3.slideTo(2);
});

const btn4 = document.querySelector('.bottle-4');
btn4.addEventListener('click', () => {
    swiper3.slideTo(3);
});


//cursor
gsap.set(".ball", { xPercent: -50, yPercent: -50, display: "flex" });

var ball = document.querySelector(".ball");
var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
var mouse = { x: pos.x, y: pos.y };
var speed = 0.6;

var xSet = gsap.quickSetter(ball, "x", "px");
var ySet = gsap.quickSetter(ball, "y", "px");

window.addEventListener("mousemove", e => {
    mouse.x = e.x;
    mouse.y = e.y;
});
gsap.ticker.add(() => {
    var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    xSet(pos.x);
    ySet(pos.y);
});

gsap.set(ball, { scale: 0 })
//function mousemoveFunction(e) {
//    mouse.x = e.x;
//    mouse.y = e.y;
//}
//
//function tickerUpdate() {
//  var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
//
//  pos.x += (mouse.x - pos.x) * dt;
//  pos.y += (mouse.y - pos.y) * dt;
//  xSet(pos.x);
//  ySet(pos.y);
//}
let cursorOff = document.querySelectorAll("[data-id='cursor']")

let section = document.querySelector(".banner-gif");
section.onmouseenter = function () {
    //section.addEventListener("mousemove", mousemoveFunction);
    //gsap.ticker.add(tickerUpdate)
    gsap.to(ball, { scale: 1 })
}

section.onmouseleave = function () {
    //section.removeEventListener("mousemove", mousemoveFunction);
    //gsap.ticker.remove(tickerUpdate)
    gsap.to(ball, { scale: 0 })
}

cursorOff.forEach((e) => {
    e.onmouseenter = function () {
        gsap.to(ball, { scale: 0 })
    }
    e.onmouseleave = function () {
        gsap.to(ball, { scale: 1 })
    }
})

$('.wrapper-text').on('mouseenter', function(){
    gsap.to('.wrapper-text h3', {
        duration: .1,
        zIndex: 99,
        scale: 1.1,
        ease: "power2.out",
    })

})
$('.wrapper-text').on('mouseleave', function(){
    gsap.to('.wrapper-text h3', {
        duration: .1,
        ease: "power2.out",
        zIndex: -1,
        scale: 1,
        position : 'relative',
    })

})
